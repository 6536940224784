(function carouselScript($, Drupal) {

  'use strict';

  function carouselSetup() {
    var $status = $('.carousel__slides'),
      $slickElement = $('.slideshow__slides'),
      $carouselInfo = $('.carousel__information');

    $slickElement.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
      var i = (currentSlide ? currentSlide : 0) + 1,
        $imageHeight = $('.slideshow__slides .slick-active img').height();
      $status.text(i + ' / ' + slick.slideCount);
      $carouselInfo.css('top', $imageHeight + 24);

    });

    $('.slideshow__slides').not('.slick-initialized').slick({
      accesibility: true,
      arrows: true,
      prevArrow: $('.carousel__arrow--prev'),
      nextArrow: $('.carousel__arrow--next'),
      appendArrows: $(this).parents('.carousel').find('.carousel__arrows')
    });

  }

  Drupal.behaviors.showCarousel = {
    attach(context) {
      if ($('.slideshow__slides').length > 0) {
        carouselSetup();
        $(window).on('resize load', function () {
          carouselSetup();
        });
      }
    }
  };
})(jQuery, Drupal);
